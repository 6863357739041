.studentHome {
  max-width: 1300px;
  width: 95%;
  margin: auto;
  padding: 2rem 0;
  h1 {
    @media (max-width: 700px) {
      font-size: 1.4rem;
    }
    @media (max-width: 400px) {
      font-size: 1.2rem;
    }
  }
  .wrapper {
    margin-top: 2rem;
    .library {
      .gradeToggler {
        display: flex;
        .select {
          width: 50%;
          &:first-child {
            margin-right: 0.6rem;
          }
        }
      }
      .contentWrapper {
        margin-top: 2rem;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 0.3rem;
        .toggle {
          display: flex;
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          p {
            cursor: pointer;
            font-size: 1rem;
            padding: 1rem 1.5rem;
            &:first-child {
              border-right: 1px solid rgba(0, 0, 0, 0.3);
            }
            @media (max-width: 768px) {
              padding: 1rem;
              padding: 0.9rem;
            }
            @media (max-width: 600px) {
              padding: 0.8rem;
            }
          }
          p.selected {
            border-bottom: 2px solid black;
            font-weight: 600;
          }
        }

        .content {
          padding: 1rem;
        }
      }
    }
  }
}
