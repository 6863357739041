.homeNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #231955;
  color: #fff;
  .image {
    display: flex;
    align-items: center;
    img {
      width: 4rem;
      height: 4rem;
      object-fit: contain;
    }
    h4 {
      display: none;
      font-size: 1rem;
      margin-left: 0.8rem;
      letter-spacing: 1px;
    }
  }
  .logo {
    .link {
      font-size: 1.3rem;
      color: #fff;
      text-decoration: none;
    }
  }

  .details {
    display: flex;
    gap: 2rem;
    p {
      font-size: 0.95rem;
      cursor: pointer;
    }
  }
}
