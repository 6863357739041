.videosWrapper {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(310px, auto));
  .item {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    .video {
      width: 100%;
      height: 13rem;
      @media (max-width: 768px) {
        height: 15rem;
      }
    }
    p {
      padding: 0.8rem;
      font-size: 1.1rem;
    }
  }
}
