@import "./../../../css/color.scss";

.login-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 2rem auto 0;
  padding: 1rem 1.5rem;
  h2 {
    font-size: 2.2rem;
    text-align: center;
    color: $primary;
  }
  form {
    width: 95%;
    margin: 2rem auto 0;
    .err-wrapper {
      margin-bottom: 1rem;
      text-align: center;
      p {
        color: red;
        font-size: 1.1rem;
      }
    }
    .input-wrapper {
      display: block;
      margin-bottom: 2rem;
      label {
        display: block;
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
      }
      input {
        width: 100%;
        padding: 0.5rem 0.5rem;
        font-size: 1.1rem;
        border: 1px solid $primary;
      }
    }
    button {
      font-size: 1.2rem;
      padding: 0.5rem 4rem;
      outline: none;
      border: none;
      background-color: $primary;
      color: #fff;
      cursor: pointer;
    }
  }
}
