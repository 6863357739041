.docsWrapper {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  .docItem {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    color: #000;
    text-decoration: none;
  }
}
