@import "./../../../css/color.scss";

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  .model-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 40%;
    padding: 1.5rem 1rem;
    text-align: center;
    border-radius: 0.4rem;
    @media (max-width: 1200px) {
      width: 65%;
    }
    @media (max-width: 1000px) {
      width: 80%;
    }
    @media (max-width: 800px) {
      width: 95%;
    }
    h2 {
      font-size: 1.8rem;
      font-weight: 400;
      color: $primary;
      @media (max-width: 900px) {
        font-size: 1.3rem;
      }
      @media (max-width: 500px) {
        font-size: 1rem;
      }
    }
    .btn-wrapper {
      margin-top: 2rem;
      .btn {
        font-size: 1rem;
        padding: 0.5rem 2rem;
        @media (max-width: 500px) {
          font-size: 0.8rem;
        }
      }
    }
    .btn.cancel {
      margin-right: 1rem;
    }
    .page-list {
      margin-top: 1.2rem;
      p {
        text-align: start;
        padding: 0.8rem 0.5rem;
        cursor: pointer;
        &:nth-child(odd) {
          background-color: rgba(0, 0, 0, 0.2);
        }
        @media (max-width: 768px) {
          font-size: 0.9rem;
        }
      }
    }
  }
}
