@import "./color.scss";

.wrapper {
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 1.6rem;
    .title {
      display: flex;
      align-items: center;
    }
    .link {
      color: #000;
      .icon {
        color: #000;
      }
    }
    .brands {
      width: 30%;
      .select {
        width: 100%;
      }
      @media (max-width: 1000px) {
        width: 50%;
      }
      @media (max-width: 768px) {
        width: 70%;
      }
    }
    .icon-wrapper {
      width: 100%;
      text-align: center;
      .add-icon {
        background-color: #194350;
        color: #fff;
        border-radius: 50%;
        font-size: 2rem;
        padding: 0.2rem;
        cursor: pointer;
      }
    }
  }
  .form-wrapper {
    margin-bottom: 3rem;
    .heading {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .icon {
        margin-right: 0.8rem;
        font-size: 1.8rem;
        cursor: pointer;
        @media (max-width: 768px) {
          font-size: 1.2rem;
          margin-right: 0.4rem;
        }
      }
    }
    h3 {
      font-size: 1.8rem;
      color: $primary;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
    .features-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
      .feature {
        background-color: #fff;
        padding: 0.8rem 1.3rem;
        border-radius: 1rem;
        color: #fff;
        cursor: pointer;
        color: $primary;
        border: 1px solid $primary;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
          padding: 0.6rem 1.3rem;
          font-size: 0.9rem;
        }
      }
      .feature.active {
        background-color: $primary;
        color: #fff;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1.3rem;
      @media (max-width: 800px) {
        width: 100%;
      }
      .doubleInputWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
        .input,
        .select {
          width: 100%;
          background-color: #fff;
          @media (max-width: 768px) {
            margin-bottom: 1rem;
          }
          &:first-child {
            margin-right: 1rem;
            @media (max-width: 768px) {
              margin-right: 0rem;
            }
          }
        }
        @media (max-width: 768px) {
          flex-direction: column;
        }
      }

      .select {
        width: 100%;
        outline: none;
        font-size: 1.1rem;
        background-color: #fff;
        &:first-child {
          margin-right: 1rem;
          @media (max-width: 768px) {
            margin-right: 0.5rem;
          }
        }
      }
      button {
        background-color: $primary;
        border: none;
        outline: none;
        font-size: 1.1rem;
        color: #fff;
        border-radius: 1rem;
        padding: 0.7rem;
        width: 50%;
        cursor: pointer;
        margin: 1.3rem auto;
        @media (max-width: 768px) {
          width: 100%;
          margin: 0.8rem auto;
        }
      }
      .check {
        cursor: pointer;
        text-align: start !important;
        display: flex;
        align-items: center;
        label {
          cursor: pointer;
          margin-right: 0.5rem;
        }
        input {
          cursor: pointer;
          margin-right: 0.5rem;
        }
      }
    }
    .display-img {
      margin-bottom: 1.5rem;
      img {
        width: 100%;
        height: 10rem;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
  table {
    width: 100%;
    th,
    td {
      padding: 0.5rem;
      font-size: 1rem;
      text-align: center;
      @media (max-width: 800px) {
        font-size: 0.8rem;
      }
    }
    th {
      color: $primary;
      background-color: #fff;
      padding: 1rem 0.5rem;
    }
    tr {
      &:nth-child(odd) {
        background-color: #d3d3d3;
      }
      background-color: #fff;
    }
    td {
      color: $primary;
      font-size: 1rem;
      @media (max-width: 800px) {
        font-size: 0.9rem !important;
      }

      img {
        width: 4.5rem;
        height: 3.3rem;
        @media (max-width: 800px) {
          width: 3rem;
          height: 3rem;
        }
      }
      @media (max-width: 800px) {
        font-size: 1rem;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .edit-icon {
        color: #194350;
        cursor: pointer;
        @media (max-width: 800px) {
          display: block;
          margin: auto !important;
          font-size: 1.1rem;
        }
      }
      .delete-icon {
        color: #fa1e0e;
        cursor: pointer;
        @media (max-width: 800px) {
          display: block;
          margin: 0.8rem auto 0;
          font-size: 1.1rem;
        }
      }
    }
  }
}

.btn {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  button {
    background-color: $primary;
    border: none;
    outline: none;
    font-size: 1.1rem;
    color: #fff;
    border-radius: 1rem;
    padding: 0.7rem;
    width: 48%;
    cursor: pointer;
    margin: 1.3rem auto;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
  .submit {
    background-color: rgb(19, 124, 19);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
}
