@import "./../../css/color.scss";

.header {
  height: 15vh;
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 18rem;
  right: 0;
  transition: 0.6s all ease;
  background-color: #fff;
  z-index: 10;
  @media (max-width: 900px) {
    left: 0;
  }
  .brand-wrapper {
    display: flex;
    align-items: center;
    .menu-icon {
      font-size: 2rem;
      cursor: pointer;
      color: $primary;
      @media (max-width: 900px) {
        font-size: 1.8rem;
      }
    }
    h3 {
      font-size: 1.6rem;
      margin-left: 1rem;
      color: $primary;
      @media (max-width: 900px) {
        font-size: 1.3rem;
        margin-left: 0.5rem;
      }
    }
  }
  .profile-wrapper {
    display: flex;
    align-items: center;
    .account-icon {
      font-size: 2rem;
      @media (max-width: 900px) {
        font-size: 1.5rem;
      }
    }
    p {
      margin-left: 0.5rem;
      color: $primary;
    }
  }
}
@media (max-width: 900px) {
  .header.responsive {
    left: 80%;
    .brand-wrapper {
      h3 {
        display: none;
      }
    }
    .profile-wrapper {
      display: none;
    }
  }
}

.navbar {
  background-color: $primary;
  width: 18rem;
  z-index: 100;
  padding-top: 2rem;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  transition: 0.6s all ease;

  .navbar-title {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    .logo {
      color: #fefefe;
      margin-right: 0.8rem;
      font-size: 1.8rem;
    }
    h2 {
      color: #fefefe;
      font-size: 1.8rem;
    }
  }
  .nav-list {
    margin-top: 2rem;
    margin-left: 2rem;
    .link {
      text-decoration: none;
      margin: 1rem 0;
      padding: 1rem 0.8rem;
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        margin-right: 0.6rem;
      }
    }
    .link.active {
      border-bottom-left-radius: 1.3rem;
      color: $primary;
      background-color: #fff;
      border-top-left-radius: 1.3rem;
    }
  }
}

@media (max-width: 900px) {
  .navbar {
    width: 0;
    left: -100%;
  }
  .navbar.responsive {
    width: 80%;
    left: 0;
  }
}
