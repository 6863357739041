@import "./color.scss";

.home-wrapper {
  position: relative;
  background-color: #f4f9f9;
  .home {
    margin-top: 15vh;
    margin-left: 18rem;
    padding: 2rem 1.5rem;
    @media (max-width: 900px) {
      margin-left: 0;
    }
    .card-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 2rem;
      .card {
        background-color: #fff;
        padding: 2rem 1.6rem;
        display: flex;
        justify-content: space-between;
        border-radius: 0.8rem;
        cursor: pointer;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        text-decoration: none;
        .icon {
          font-size: 2rem;
          color: $primary;
        }
        h1 {
          font-size: 2rem;
          color: $primary;
        }
        p {
          color: $secondary;
          margin-top: 0.6rem;
          font-size: 1.1rem;
        }
      }

      .card.first {
        background-color: $primary;
        h1,
        .icon,
        p {
          color: #fff !important;
        }
      }
    }
  }
}
